<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-5">
        {{
          inputs.language === 'en'
            ? 'What is the absolute configuration of the indicated stereogenic centers?'
            : 'Quelle est la configuration absolue des centres stéréogènes indiqués?'
        }}
      </p>

      <p class="pl-8 mb-6">
        <v-img style="max-width: 319px" :src="imageName" />
      </p>

      <p class="mb-0">
        {{ inputs.language === 'en' ? 'Atom A:' : 'Atome A:' }}
        <v-select
          v-model="inputs.studentAnswer1"
          class="d-sm-inline-block ml-3 mr-3"
          style="width: 120px"
          dense
          :items="items"
        />
      </p>

      <p class="mb-0">
        {{ inputs.language === 'en' ? 'Atom B:' : 'Atome B:' }}
        <v-select
          v-model="inputs.studentAnswer2"
          class="d-sm-inline-block ml-3 mr-3"
          style="width: 120px"
          dense
          :items="items"
        />
      </p>

      <p class="mb-0">
        {{ inputs.language === 'en' ? 'Atom C:' : 'Atome C:' }}
        <v-select
          v-model="inputs.studentAnswer3"
          class="d-sm-inline-block ml-3 mr-3"
          style="width: 120px"
          dense
          :items="items"
        />
      </p>

      <p class="mt-5 mb-0">
        <v-btn-toggle
          v-model="inputs.language"
          class="ml-2"
          size="small"
          dense="dense"
          rounded="rounded"
          mandatory
        >
          <v-btn :value="'en'">English</v-btn>
          <v-btn :value="'fr'">Français</v-btn>
        </v-btn-toggle>
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';

export default {
  name: 'Question522',
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        studentAnswer1: null,
        studentAnswer2: null,
        studentAnswer3: null,
        language: 'fr',
      },
      items: [
        {text: 'R', value: 'R'},
        {text: 'S', value: 'S'},
      ],
    };
  },
  computed: {
    imageName() {
      return '/img/assignments/q522.png';
    },
  },
};
</script>
<style scoped></style>
